import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import { BrowserRouter } from 'react-router-dom';
import { UserContextProvider } from './contexts/userContext.js';
import { SignalRProvider } from './contexts/signalrContext.js';
import { ConfirmProvider } from 'material-ui-confirm';
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';
import Dashboard from './scenes/dashboard';
import Plmnid from './scenes/plmnid/PlmnId';
import Snssai from './scenes/snssai/Snssai';
import PlmnInfo from './scenes/plmninfo/PlmnInfo';
import Vendors from './scenes/vendors/Vendors';
import ManagementNodes from './scenes/managementNodes/ManagementNodes';
import NetworkFunctions from './scenes/networkFunctions/NetworkFunctions';
import NetworkServices from './scenes/networkServices/NetworkServices';
import Alarms from './scenes/notifications/Alarms';
import Notifications from './scenes/notifications/Notifications';
import DataView from './scenes/dataView/dataView.jsx';

import { SnackbarProvider } from './contexts/snackbarContext.js';

function App() {
	const [theme, colorMode] = useMode();
	const [isSidebar, setIsSidebar] = useState(true);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>
				<UserContextProvider>
					<SignalRProvider>
						<CssBaseline />
						<ConfirmProvider>
							<div className='app'>
								<BrowserRouter>
									<Sidebar isSidebar={isSidebar} />
									<main className='content'>
										<SnackbarProvider>
											<Topbar setIsSidebar={setIsSidebar} />
										</SnackbarProvider>
										<Routes>
											<Route path='/' element={<Dashboard />} />
											<Route path='/plmnid' element={<Plmnid />} />
											<Route path='/snssai' element={<Snssai />} />
											<Route path='/plmninfo' element={<PlmnInfo />} />
											<Route path='/vendors' element={<Vendors />} />
											<Route path='/mnem' element={<ManagementNodes />} />
											<Route path='/nfs' element={<NetworkFunctions />} />
											<Route path='/ns' element={<NetworkServices />} />
											<Route path='/dataview' element={<DataView />} />

											<Route
												path='/notifications'
												element={<Notifications />}
											/>
											<Route path='/alarms' element={<Alarms />} />
										</Routes>
									</main>
								</BrowserRouter>
							</div>
						</ConfirmProvider>
					</SignalRProvider>
				</UserContextProvider>
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
}

export default App;

import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export const buildDataURL = (apiPath) => {
	return new URL(
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_API_BASE_PATH + '/snsm' + apiPath
			: process.env.REACT_APP_DEV_API_BASE_PATH + '/snsm' + apiPath,
		process.env.NODE_ENV === 'production'
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);
};

export const buildQueueURL = (apiPath) => {
	return new URL(
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_QUEUE_BASE_PATH + apiPath
			: process.env.REACT_APP_DEV_QUEUE_BASE_PATH + apiPath,
		process.env.NODE_ENV === 'production'
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);
};

export const buildRelayURL = () => {
	return new URL(
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_RELAY_BASE_PATH
			: process.env.REACT_APP_DEV_RELAY_BASE_PATH,
		process.env.NODE_ENV === 'production'
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);
};

export const buildChathubURL = () => {
	return new URL(
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PROD_CHATHUB_BASE_PATH
			: process.env.REACT_APP_DEV_CHATHUB_BASE_PATH,
		process.env.NODE_ENV === 'production'
			? window.location.origin
			: process.env.REACT_APP_DEV_API_BASE_URL
	);
};

export function UseQuery() {
	return new URLSearchParams(useLocation().search);
}

export function Child({ name }) {
	return (
		<div>
			{name ? (
				<h3>
					The <code>name</code> in the query string is &quot;{name}
					&quot;
				</h3>
			) : (
				<h3>There is no name in the query string</h3>
			)}
		</div>
	);
}

export const overrideStyle = (innerStyle, outerStyle) => {
	let mergedStyle = { ...outerStyle, ...innerStyle };
	if (outerStyle) {
		Object.keys(outerStyle).map((okey) => {
			Object.keys(innerStyle).map((ikey) => {
				if (ikey === okey) {
					mergedStyle = { ...mergedStyle, [ikey]: outerStyle[okey] };
				}
			});
		});
		return mergedStyle;
	} else return innerStyle;
};

export const generateUniqueKey = (obj) => {
	return { ...obj, uniqueKey: uuidv4() };
};

export const isNonEmptyString = (value) => {
	return value != null && typeof value === 'string' && value !== '';
};

export const isNonEmptyNumber = (value) => {
	return (
		value != null && typeof value === 'number' && value !== '' && !isNaN(value)
	);
};

export const isNonEmptyArray = (value) => {
	return value != null && typeof value === 'object' && value.length > 0;
};

export const isNonEmptyObject = (value) => {
	return (
		value != null && typeof value === 'object' && JSON.stringify(value) !== '{}'
	);
};

export const countRequiredInSchema = (schema) => {
	let count = 0;
	Object.entries(schema).map((item) => {
		const k = item[0];
		const v = item[1];
		v.hasOwnProperty('mandatory') && v.mandatory && count++;
	});
	return count;
};

export const getArrayOfMandatoryIfInSchema = (schema) => {
	let mandatories = [];
	Object.entries(schema).map((item) => {
		const property = item[0];
		const entry = item[1];

		if (entry.hasOwnProperty('mandatoryIf')) {
			entry.mandatoryIf.forEach((el) => {
				el = JSON.stringify(el);

				let index = null;
				const found = mandatories.find((item, i) => {
					const tmp = el.slice(0, el.length - 1);
					if (item.includes(tmp)) index = i;
					return item.includes(tmp);
				});

				if (found) {
					let tmp = JSON.parse(mandatories[index]);
					tmp.num++;
					tmp = JSON.stringify(tmp);
					mandatories[index] = tmp;
				} else {
					el = JSON.parse(el);
					el.num = 1;
					el = JSON.stringify(el);
					mandatories.push(el);
				}
			});
		}
	});

	mandatories = mandatories.map((el) => JSON.parse(el));
	return mandatories;
};

export const getTotalRequired = (
	howManyRequiredInSchema,
	mandatoryIfInSchema,
	dataToSend
) => {
	let currentRequired = 0;
	Object.entries(dataToSend).forEach((a, b) => {
		let dataProperty = a[0];
		let dataValue = a[1];
		mandatoryIfInSchema.forEach((el, index) => {
			Object.entries(el).forEach((c, d) => {
				let schemaProperty = c[0];
				let schemaValue = c[1];
				if (dataProperty === schemaProperty && dataValue === schemaValue) {
					currentRequired = mandatoryIfInSchema[index].num;
				}
			});
		});
	});
	return currentRequired + howManyRequiredInSchema;
};

export const checkRequiredFieldNumber = (
	requiredInSchema,
	tmpDialog,
	schema,
	dataToSend,
	actionButtons = null
) => {
	let countCurrentRequired = 0;
	let checked = false;

	Object.entries(schema).map((item) => {
		const property = item[0];
		const entry = item[1];

		const dataToSendPropertyIsPresent = Object.keys(dataToSend).find((el) => {
			return el === property;
		});

		const isConditionFullfilled = checkIsConditionFullfilled(
			dataToSend,
			dataToSend[property],
			entry,
			'mandatory'
		);

		if (isConditionFullfilled === 'non mandatory') return;

		if (dataToSendPropertyIsPresent && isConditionFullfilled) {
			countCurrentRequired++;
			if (isNonEmptyObject(tmpDialog)) {
				tmpDialog.dialog.buttons.forEach((b) => {
					if (b.type === 'save') {
						checked = countCurrentRequired === requiredInSchema;
					}
				});
			} else {
				actionButtons.forEach((b) => {
					if (b.icon === 'save') {
						checked = countCurrentRequired === requiredInSchema;
					}
				});
			}
		}
	});
	return checked;
};

export const checkPropertyIfConditions = (objCondition, objIf, formData) => {
	if (objCondition == null && objIf == null) return false;
	const objIfCondition = objIf?.every((el) => {
		const [elKey, elValue] = Object.entries(el)[0];
		return formData[elKey] === elValue;
	});

	if (objCondition != null) return objCondition;
	if (objIfCondition != null) return objIfCondition;
};

export const checkIsConditionFullfilled = (
	formData,
	formDataValue,
	schemaEntry,
	condition
) => {
	if (condition === 'disabled')
		return checkPropertyIfConditions(
			schemaEntry.disabled,
			schemaEntry.disabledIf,
			formData
		);

	const isMandatory = checkPropertyIfConditions(
		schemaEntry.mandatory,
		schemaEntry.mandatoryIf,
		formData
	);

	if (!isMandatory) return 'non mandatory';

	if (schemaEntry.type === 'number' && formDataValue === '_') return false;

	const isConditionFullfilled =
		isNonEmptyString(formDataValue) ||
		isNonEmptyNumber(formDataValue) ||
		isNonEmptyArray(formDataValue);

	return isConditionFullfilled;
};

export const checkWhichMissingFields = (data, fields) => {
	let whichOneAreMissing = [];
	Object.entries(fields).forEach((k, v) => {
		const field = k[0];
		const value = k[1];
		if (value.hasOwnProperty('mandatory') && value.mandatory) {
			if (
				!isNonEmptyString(data[field]) &&
				!isNonEmptyNumber(data[field]) &&
				!isNonEmptyArray(data[field])
			) {
				whichOneAreMissing.push(value.title);
			}
		}
	});
	return whichOneAreMissing;
};

export const fromDBDateToGUIDate = (date) => {
	//example of timezone with 30min in it
	// let options = {
	//     timeZone: 'Asia/Tehran',
	//     hour12: false,
	//     year: 'numeric',
	//     month: '2-digit',
	//     day: '2-digit',
	//     hour: '2-digit',
	//     minute: '2-digit',
	//     second: '2-digit',
	//     timeZoneName: 'shortOffset',
	//   },
	//   formatter = new Intl.DateTimeFormat([], options)

	// console.log(formatter.format(new Date()))

	// console.log(date)
	// console.log(new Date(date).toISOString())
	// console.log(new Date(date).toLocaleDateString())
	const intlDate = new Intl.DateTimeFormat('en-US', {
		hour12: false,
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		timeZoneName: 'shortOffset',
	}).format(new Date(date));

	// 04/21/2023, 17:28:45 GMT+2

	//2023-04-26 15:55:48 GMT+2

	const month = intlDate.split('/')[0];
	const day = intlDate.split('/')[1];
	const year = intlDate.split('/')[2].split(',')[0];
	const time = intlDate.split(', ')[1];
	return `${year}-${month}-${day} ${time}`;
};

export const convertToDate = (datetime) => {
	const formatted = new Date(datetime).toLocaleString('it-IT', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		hour12: false,
	});
	const date = formatted.split(', ')[0];
	const time = formatted.split(', ')[1];
	return `${date} ${time}`;
};
